import React, { useState } from "react";
import MyRealMath from "components/commons/MyRealMath";
import ReactHtmlParser from 'react-html-parser';

import testsApi from "apis/tests";
import ResultPercentage from "components/commons/ResultPercentage";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

export default function TypingTextOrdered({
  test
}) {
  const submitAnswers = async (resultsToSubmit) => {
    try {
      testsApi.submitAnswers(test.id, {results: resultsToSubmit})
    } catch (error) {
      logger.error(error)
    } finally {
      setAvailableTiles([])
      setRevealedTiles(test.tiles)
      setIsRetake(false)
    }
  }

  const retake = () => {
    setIsRetake(true)
    setResults({})
    setCurrentTile(test.tiles[0])
    setAvailableTiles(test.tiles)
    setRevealedTiles([])
  }

  function prevTile() {
    let newIndex = (availableTiles.indexOf(currentTile) + availableTiles.length - 1) % availableTiles.length
    setCurrentTile(availableTiles[newIndex])
    setInputText('')
  }

  function nextTile() {
    let newIndex = (availableTiles.indexOf(currentTile) + 1) % availableTiles.length
    setCurrentTile(availableTiles[newIndex])
    setInputText('')
  }

  const tileClicked = (tile) => {
    if (!revealedTiles.includes(tile)) {
      setCurrentTile(tile)
    }
  }

  function isCorrect(tile) {
    return normalize(results[tile.id]) == normalize(tile.title)
  }

  function correctAnswers() {
    return Object.entries(results).filter(([key, value]) => normalize(test.tiles.find((t) => t.id === key).title) === normalize(value))
  }

  function isFinished() {
    // console.log(availableTiles.length, !isRetake, test.last_test_taking?.id)
    return availableTiles.length === 0 || (!isRetake && test.last_test_taking)
  }

  function isCurrent(tile) {
    return currentTile.id === tile.id && !isFinished()
  }

  function normalize(string) {
    return string?.toLowerCase()?.replace(/\s/g, '')
  }

  function Navigation() {
    const onChange = (event) => {
      setInputText(event.target.value)

      if (normalize(currentTile.title) === normalize(event.target.value)) {
        setAvailableTiles(availableTiles.filter(t => t.id !== currentTile.id))
        setRevealedTiles([...revealedTiles, currentTile]);

        let answer = {}
        answer[currentTile.id] = event.target.value
        setResults({ ...results, ...answer });
        nextTile()

        if (availableTiles.length === 1) {
          submitAnswers({ ...results, ...answer })
        }

        setInputText('')
      }
    }

    return (
      <div className="sticky top-[63px] w-full pt-5 bg-white z-20 text-center">
        <label htmlFor="inputText" className="max-w-[70%] m-auto block text-sm font-medium leading-6 text-neutral-900 text-center">
        <MyRealMath text={ReactHtmlParser(currentTile.prompt)}/>
        </label>
        <span className="isolate inline-flex rounded-md p-4">
          <button
            type="button"
            onClick={prevTile}
            className="relative inline-flex items-center rounded-l-md bg-white px-2.5 py-2.5 text-neutral-700 ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50"
          >
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <div className="inline-flex">
            <input
              type="text"
              name="inputText"
              id="inputText"
              autoComplete="off"
              autoFocus
              placeholder="Вашият отговор…"
              className="min-w-[200px] sm:min-w-[300px] block border-0 relative -ml-px w-[calc(100%+1px)] py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus:z-10 sm:text-sm sm:leading-6"
              value={inputText}
              onChange={onChange}
            />
          </div>
          <button
            type="button"
            onClick={nextTile}
            className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2.5 py-2.5 text-neutral-700 ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50"
          >
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </span>
      </div>
    )
  }

  function initialAvailableTiles() {
    return test.last_test_taking ? [] : test.tiles
  }

  function initialRevealedTiles() {
    if (test.last_test_taking) {
      return test.tiles
    }

    return []
  }

  const [inputText, setInputText] = useState('')
  const [currentTile, setCurrentTile] = useState(test.tiles[0])
  const [availableTiles, setAvailableTiles] = useState(initialAvailableTiles)
  const [revealedTiles, setRevealedTiles] = useState(initialRevealedTiles())
  const [results, setResults] = useState(test.last_test_taking?.results || {})
  const [isRetake, setIsRetake] = useState(false)

  return (
    <>
      <h2 className="text-center mt-5 sm:mt-1">
        {test.title}
      </h2>

      {test.intro && (
        <h4 className="md:max-w-3xl m-auto pt-4 pb-4 sm:pb-14 text-center text-l font-normal text-neutral-600 leading-6">
          {ReactHtmlParser(test.intro)}
        </h4>
      )}
      {test.image_url && (
        <img src={test.image_url} className="m-auto p-6"/>
      )}

      <div className="flex flex-col items-center justify-center">
        {isFinished() && (
          <ResultPercentage correct={correctAnswers().length} total={test.tiles.length} />
        )}
        {!isFinished() && <Navigation/>}

        <div className="min-w-[200px] sm:w-[600px] md:w-[700px] lg:w-[800px] px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="border min-w-full divide-y divide-neutral-300 text-center">
                  <thead>
                    <tr className="divide-x divide-neutral-200 bg-neutral-100">
                      <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-neutral-900 text-center">
                        Въпрос
                      </th>
                      <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-neutral-900 text-center">
                        Отговор
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-neutral-200 bg-white">
                    {test.tiles.map((tile) => (
                      <tr key={tile.id} onClick={() => tileClicked(tile)} className={`${isCurrent(tile) ? 'bg-neutral-50' : ''} divide-x divide-neutral-200`}>
                        <td className={`${isCurrent(tile) ? 'text-neutral-950' : 'text-neutral-600'} whitespace-normal py-4 pl-4 pr-4 text-sm font-normal`}><MyRealMath text={ReactHtmlParser(tile.prompt)}/></td>
                        <td className={`${isCurrent(tile) ? 'shadow-prominent z-10' : ''} ${isCorrect(tile) ? 'bg-green-100' : ''} ${isFinished() && !isCorrect(tile) ? 'bg-red-100' : ''} lg:min-w-[180px] max-w-[50%] whitespace-normal font-medium text-neutral-700`}>
                          {revealedTiles.includes(tile) && (<span className="animate-fadeQuick"><MyRealMath text={ReactHtmlParser(tile.title)}/></span>)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {
          !isFinished() && (
            <button onClick={() => submitAnswers(results)} type="button" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 font-medium rounded-md text-sm px-5 py-2.5 m-5 dark:bg-primary-600 dark:hover:bg-primary-600 focus:outline-none dark:focus:ring-primary-700">Завърши теста</button>
          )
        }

        {
          isFinished() && (
            <button onClick={retake} type="button" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 font-medium rounded-md text-sm px-5 py-2.5 m-5 dark:bg-primary-600 dark:hover:bg-primary-600 focus:outline-none dark:focus:ring-primary-700">Повтори теста отново</button>
          )
        }
      </div>
    </>
  )
}
