import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import testsApi from "apis/tests";
import BackButton from "components/commons/BackButton";
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { PageLoader } from "neetoui";

import Classic from "./Classic";
import ImageClick from "./ImageClick";
import TypingTextOrdered from "./TypingTextOrdered";
import TestFeedback from "./TestFeedback";

import { MathJaxContext } from "better-react-mathjax";
import Mathable from "components/commons/Mathable";
import MyScrollToTop from "components/commons/MyScrollToTop";

const Test = () => {
  const [loading, setLoading] = useState(true);
  const [test, setTest] = useState({});

  useEffect(() => {
    fetchTest();
  }, []);

  let { id } = useParams();

  const fetchTest = async () => {
    try {
      setLoading(true);
      const {
        data: { test },
      } = await testsApi.fetchTest(id);

      setTest(test);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="animate-fade py-10">
             <PageLoader text="Един момент..."/>
           </div>;
  }

  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [["$", "$"]],
      displayMath: [["$$", "$$"]]
    }
  };

  return (
    <>
      <div className="flex items-center gap-x-3">
        <BackButton/>
        <TestFeedback test={test} />
      </div>
      <MathJaxContext config={config} version={3}>
        {test.kind === 'classic' && <Mathable content={<Classic test={test}/>}/>}
      </MathJaxContext>
      {test.kind === 'image_click' && <ImageClick test={test}/>}
      {test.kind === 'typing_text_ordered' && <TypingTextOrdered test={test}/>}
      <MyScrollToTop />
    </>
  );
};

export default Test;
