import React, { useState } from "react"
import ReactDOM from 'react-dom'
import { getFromLocalStorage } from "utils/storage";

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

export default function UploadSolution({
  onFileProcessed,
  onFileCanceled,
  subquestion = null
}) {
  const [files, setFiles] = useState([])

  return (
    <div>
      <FilePond
        files={files}
        onprocessfile={function(error, file) {
          setFiles([file]);
          onFileProcessed(file, subquestion);
        }}
        onremovefile={function(error, file) {
          setFiles([]);
          onFileCanceled(subquestion);
        }}
        allowMultiple={false}
        maxFiles={1}
        maxFileSize={'5MB'}
        labelMaxFileSizeExceeded={'Файлът е прекалено голям'}
        labelMaxFileSize={'Максималният размер на файла е {filesize}'}
        labelFileProcessingComplete={'Качването е успешно'}
        labelFileProcessing={'Качване'}
        labelFileProcessingError={'Грешка в качването'}
        labelTapToCancel={'Натиснете тук за спиране'}
        labelTapToRetry={'Натиснете за да опитате пак'}
        labelTapToUndo={'Натиснете за отказ'}
        acceptedFileTypes={['image/*', 'application/pdf', 'application/msword']} // allow zip too
        // headers must be the same as those from app/javascript/src/apis/axios.js
        server={{
          url: "/api/v1/files",
          headers: {
            'X-Auth-Email': getFromLocalStorage("authEmail"),
            'X-Auth-Token': getFromLocalStorage("authToken"),
            'X-CSRF-TOKEN': document.querySelector('[name="csrf-token"]').getAttribute("content")
          }
        }}
        name="file" /* sets the file input name, it's filepond by default */
        labelIdle='<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 icon-next-to-file-upload"> <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" /> </svg> <span class="text-for-file-upload">Поставете файл директно тук или <span class="filepond--label-action">Изберете от папка</span></span>'
        credits={false}
      />
    </div>
  )
}
