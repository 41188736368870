import React, { useEffect } from "react";
import { DASHBOARD_PATH } from "components/routeConstants";
import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";

import qs from 'qs';

const OauthCallbackPage = ({ history }) => {
  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  useEffect(() => {
    const data = qs.parse(Object.fromEntries(new URLSearchParams(location.search)));
    const auth_token = data.auth_token
    const is_admin = data.is_admin === 'true'
    const email = data.user.email
    const user = data.user

    authDispatch({ type: "LOGIN", payload: { auth_token, email, is_admin } });
    userDispatch({ type: "SET_USER", payload: { user } });
    history.push(DASHBOARD_PATH);
  })

  return null;
};

export default OauthCallbackPage;
