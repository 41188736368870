import React from "react";

import { useHistory } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { HOME_PATH } from "components/routeConstants"

const BackButton = () => {
  let history = useHistory()

  return (
    <>
      <button type="button"
        className="inline-flex items-center gap-x-1.5 rounded bg-primary-600 px-3 py-1.5 my-1.5 text-sm font-medium text-white shadow-sm hover:bg-primary-700 transition-colors duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        onClick={() => {
          history.goBack();

          // this doesn't behave well with nested clicks and going back twice
          // if (history.action !== 'POP') {
          //   history.goBack();
          // } else {
          //   history.push(HOME_PATH)
          // }
        }}
        >
        <ChevronLeftIcon className="-mr-0.5 h-3 w-3" aria-hidden="true" />
        Назад
      </button>
    </>
  );
};

export default BackButton;
