import React, { useState } from "react";

import { FaceSmileIcon as FaceSmileIconOutline, PaperClipIcon } from '@heroicons/react/24/outline'
import { Listbox } from '@headlessui/react'
import {
  FaceFrownIcon,
  FaceSmileIcon as FaceSmileIconMini,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import autosize from 'autosize';

const moods = [
  { name: 'Развълнуван', value: 'развълнуван', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
  { name: 'Позитивен', value: 'позитивен', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
  { name: 'Задоволен', value: 'задоволен', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
  { name: 'Недоволен', value: 'недоволен', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
  { name: 'Не чувствам нищо', value: null, icon: XMarkIcon, iconColor: 'text-neutral-400', bgColor: 'bg-transparent' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CommentTextarea({
  comment,
  setComment,
  showEmoji,
  emoji,
  setEmoji
}) {
  const [selected, setSelected] = useState(moods[4])

  function onTextareaChange(value) {
    autosize(value.target);
    setComment(value.target.value);
  }

  function onMoodChange(mood) {
    setSelected(mood);
    setEmoji(mood.value);
  }

  return (
    <div className="flex items-start space-x-4">
      <div className="min-w-0 flex-1">
        <form action="#">
          <div className="border-b border-neutral-200 focus-within:border-primary-600">
            <textarea
              id="comment"
              name="comment"
              rows={3}
              placeholder="Оставете коментар..."
              className="block w-full resize-none border-0 border-b border-transparent p-0 pb-2 text-neutral-900 placeholder:text-neutral-400 focus:border-primary-600 focus:ring-0 sm:text-sm/6"
              defaultValue={''}
              onChange={onTextareaChange}
            />
          </div>
          {showEmoji && (
            <div className="flex justify-between pt-2">
              <div className="flex items-center space-x-5">
                <div className="flow-root">
                  <Listbox value={selected} onChange={onMoodChange}>
                    <div className="relative">
                      <Listbox.Button className="relative -m-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-neutral-400 hover:text-neutral-500">
                        <span className="flex items-center justify-center">
                          {selected.value === null ? (
                            <span>
                              <FaceSmileIconOutline aria-hidden="true" className="h-6 w-6 shrink-0" />
                              <span className="sr-only">Add your mood</span>
                            </span>
                          ) : (
                            <span>
                              <span
                                className={classNames(
                                  selected.bgColor,
                                  'flex h-8 w-8 items-center justify-center rounded-full',
                                )}
                              >
                                <selected.icon aria-hidden="true" className="h-5 w-5 shrink-0 text-white" />
                              </span>
                              <span className="sr-only">{selected.name}</span>
                            </span>
                          )}
                        </span>
                      </Listbox.Button>

                      <Listbox.Options
                        transition="true"
                        className="absolute z-10 -ml-6 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:ml-auto sm:w-64 sm:text-sm"
                      >
                        <div className="p-3">
                          Чувствам се...
                        </div >
                        {moods.map((mood) => (
                          <Listbox.Option
                            key={mood.value}
                            value={mood}
                            className="relative cursor-default select-none bg-white px-3 py-2 hover:bg-neutral-100"
                          >
                            <div className="flex items-center">
                              <div
                                className={classNames(
                                  mood.bgColor,
                                  'flex h-8 w-8 items-center justify-center rounded-full',
                                )}
                              >
                                <mood.icon
                                  aria-hidden="true"
                                  className={classNames(mood.iconColor, 'h-5 w-5 shrink-0')}
                                />
                              </div>
                              <span className="ml-3 block truncate font-medium">{mood.name}</span>
                            </div>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
