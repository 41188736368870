import React from "react";
import { NavLink } from "react-router-dom";

import { Form, Formik } from "formik";
import { Button } from "neetoui";
import { Input } from "neetoui/formik";
import PropTypes from "prop-types";

import authenticationApi from "apis/authentication";
import {
  SIGNUP_PATH,
  WAITLIST_PATH,
  RESET_PASSWORD_PATH,
  DASHBOARD_PATH,
} from "components/routeConstants";
import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";

import {
  LOGIN_FORM_INITIAL_VALUES,
  LOGIN_FORM_VALIDATION_SCHEMA,
} from "./constants";

import LogoSpin from "components/commons/LogoSpin";
import backgroundImage from '../Public/images/background-call-to-action.jpg'
import FacebookLoginButton from './FacebookLoginButton'

const Login = ({ history }) => {
  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  const handleSubmit = async ({ email, password }) => {
    try {
      const {
        data: { auth_token, user, is_admin },
      } = await authenticationApi.login({ email, password });
      authDispatch({ type: "LOGIN", payload: { auth_token, email, is_admin } });
      userDispatch({ type: "SET_USER", payload: { user } });
      history.push(DASHBOARD_PATH);
    } catch (error) {
      logger.error(error);
    }
  };

  function loginForm() {
    return (
      <div className="neeto-ui-bg-neutral-100 flex h-screen w-screen flex-row items-center justify-center overflow-y-auto overflow-x-hidden p-6">
        <div className="mx-auto flex h-full w-full flex-col items-center justify-center sm:max-w-md">
          <NavLink to="/" className="mb-10">
            <LogoSpin height="small" />
          </NavLink>
          <Formik
            initialValues={LOGIN_FORM_INITIAL_VALUES}
            validationSchema={LOGIN_FORM_VALIDATION_SCHEMA}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <>
                <div className="neeto-ui-rounded-md neeto-ui-bg-white neeto-ui-shadow-s w-full border p-10">
                  <h2 className="neeto-ui-text-neutral-800 mb-5 text-center text-2xl font-extrabold">
                    Влезте в профила си
                  </h2>
                  <div className="pt-5">
                    <FacebookLoginButton />
                    <p className="text-center font-light text-neutral-600 pt-5 pb-4"> или вход с имейл </p>
                  </div>
                  <Form className="w-full space-y-6">
                    <Input
                      data-cy="login-email-text-field"
                      label="Имейл"
                      name="email"
                      placeholder=""
                      type="email"
                    />
                    <Input
                      data-cy="login-password-text-field"
                      label="Парола"
                      name="password"
                      placeholder="********"
                      type="password"
                    />
                    <Button
                      fullWidth
                      className="h-9 bg-primary-600 hover:bg-primary-700"
                      data-cy="login-submit-button"
                      disabled={isSubmitting}
                      label="Вход"
                      loading={isSubmitting}
                      size="small"
                      type="submit"
                    />
                  </Form>
                  <div className="mt-4 flex flex-col items-center justify-center space-y-2">
                    <div className="flex flex-row items-center justify-start space-x-1">
                      <p className="neeto-ui-text-neutral-600 font-normal">
                        Нямате акаунт?
                      </p>
                      <Button
                        data-cy="sign-up-link"
                        label="Регистрирайте се"
                        size="small"
                        style="link"
                        className="text-primary-600 hover:text-primary-700"
                        to={SIGNUP_PATH}
                      />
                    </div>
                    <Button
                      data-cy="forgot-password-link"
                      label="Към началната страница"
                      size="small"
                      style="link"
                      className="text-primary-600 hover:text-primary-700"
                      to={DASHBOARD_PATH}
                    />
          {/*          <Button
                      data-cy="forgot-password-link"
                      label="Забравена парола?"
                      size="small"
                      style="link"
                      className="text-primary-600 hover:text-primary-700"
                      to={RESET_PASSWORD_PATH}
                    />*/}
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    )
  }

  return (
    <div className="relative">
      <video loop autoPlay playsInline muted loop className="absolute z-minus top-0 left-0 w-full h-full object-cover">
        <source src="w1.mp4" type="video/mp4" />
      </video>

      {loginForm()}
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default Login;
