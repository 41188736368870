import React, { useEffect } from "react";

const CategoryFilter = ({
  category,
  setCategory
}) => {
  const onChange = (value) => {
    if (value.target.checked === false) {
      setCategory(category.filter(cat => cat !== value.target.id))
    } else {
      setCategory([...category, value.target.id])
    }
  }

  const isChecked = (categoryName) => {
    return category.includes(categoryName)
  }

  return (
    <>
      <h3 className="text-base font-semibold leading-6 text-neutral-900">Изберете категория</h3>
      <div className="mt-2 max-w-l text-sm text-neutral-500">
        <p>
          Задължителните тестове директно покриват учебния материал и наподобяват контролна работа в училище. Развлекателните тестове предлагат по-любопитен прочит на материала и целят да бъдат както полезни, така и забавни.
        </p>
      </div>
      <div className="mt-5"></div>
      <ul className="grid w-full gap-6 md:grid-cols-2">
        <li>
          <input type="checkbox" id="mandatoryCategory" value="" className="hidden peer" required="" onChange={onChange} checked={isChecked('mandatoryCategory')} />
          <label htmlFor="mandatoryCategory" className="inline-flex items-center justify-between w-full py-3 px-5 text-neutral-500 bg-white border-2 border-neutral-200 rounded-sm cursor-pointer dark:hover:text-neutral-300 dark:border-neutral-700 peer-checked:border-primary-800 hover:text-neutral-600 dark:peer-checked:text-neutral-300 hover:bg-neutral-50 transition-colors duration-200 ease-in-out dark:text-neutral-400 dark:bg-neutral-800 dark:hover:bg-neutral-700 peer-checked:text-neutral-100 peer-checked:bg-primary-700">
            <div className="block">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mb-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
              </svg>

              <div className="w-full text-base font-semibold">Задължителни</div>
              <div className="w-full text-sm font-light">Тестове директно по задължителната учебна програма.</div>
            </div>
          </label>
        </li>
        <li>
          <input type="checkbox" id="casualCategory" value="" className="hidden peer" onChange={onChange} checked={isChecked('casualCategory')} />
          <label htmlFor="casualCategory" className="inline-flex items-center justify-between w-full py-3 px-5 text-neutral-500 bg-white border-2 border-neutral-200 rounded-sm cursor-pointer dark:hover:text-neutral-300 dark:border-neutral-700 peer-checked:border-primary-800 hover:text-neutral-600 dark:peer-checked:text-neutral-300 hover:bg-neutral-50 transition-colors duration-200 ease-in-out dark:text-neutral-400 dark:bg-neutral-800 dark:hover:bg-neutral-700 peer-checked:text-neutral-100 peer-checked:bg-primary-700">
            <div className="block">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mb-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
              </svg>

              <div className="w-full text-base font-semibold">Развлекателни</div>
              <div className="w-full text-sm font-light">Тестове с развлекателен характер по учебния материал.</div>
            </div>
          </label>
        </li>
      </ul>
    </>
  );
};

export default CategoryFilter;
