import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function DialogModal({
  title,
  subtitle,
  iconOnTop,
  onProceed,
  cancelLabel,
  proceedLabel,
  dialogClasses = 'bg-white sm:max-w-lg',
  proceedButtonColorClasses = 'bg-primary-600 hover:bg-primary-500 focus-visible:outline-primary-600',
  content = null
}) {
  const [open, setOpen] = useState(true)

  const cancelButtonRef = useRef(null)

  function proceedClicked() {
    setOpen(false)
    setTimeout(() => {
      onProceed()
    }, '200');
  }

  function initialDialog() {
    return (
      <>
        <div>
          {iconOnTop}
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-neutral-900">
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-neutral-500">
                {subtitle}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:col-start-2 ${proceedButtonColorClasses}`}
            onClick={() => proceedClicked()}
          >
            {proceedLabel}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 sm:col-start-1 sm:mt-0 outline-none"
            onClick={() => setOpen(false)}
            ref={cancelButtonRef}
          >
            {cancelLabel}
          </button>
        </div>
      </>
    )
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500/50 my-backdrop-blur" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            <Dialog.Panel className={`relative transform rounded-lg p-2 sm:px-4 sm:pb-4 sm:pt-5 text-left shadow-xl transition-all sm:w-full my-20 sm:p-6 lg:ml-72 ${dialogClasses}`}>
              {content && (
                <>
                  {content}
                </>
              )}
              {!content && initialDialog()}
            </Dialog.Panel>

            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
