import React from "react";

export default function FacebookLoginButton() {
  return (
    <form method="post" action="/devise/users/auth/facebook">
      <button type="submit" className="py-2 px-4 max-w-md  flex justify-center items-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
        <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
          <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"></path>
        </svg>
        Вход с Facebook
      </button>
      <input type="hidden" name="authenticity_token" value={document.querySelector('[name="csrf-token"]').getAttribute("content")} />
    </form>
  )
}
