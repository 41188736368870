import React from "react";
import { StarIcon } from '@heroicons/react/24/solid'

export default function StarRating ({
  rating,
  setRating
}) {
  return (
    <>
      <div className="flex flex-row-reverse justify-center p-5">
        {[5, 4, 3, 2, 1].map((value) => (
          <StarIcon
            key={value}
            className={`peer peer-hover:text-yellow-500 hover:text-yellow-500 w-7 h-7 mx-0.5 transition-all duration-300 ease-in-out ${rating != null && rating >= value ? 'text-yellow-500' : 'text-neutral-300'}`}
            onClick={() => setRating(value)}
          />
        ))}
      </div>
    </>
  )
}
