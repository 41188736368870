import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import { RadioGroup } from '@headlessui/react'
// import { isMobile } from 'react-device-detect';

import { subscribe, publish } from "common/events";
import Tooltip from "components/commons/Tooltip";
import MyMath from "components/commons/MyMath";
import MyRealMath from "components/commons/MyRealMath";
import FilePreview from "components/commons/FilePreview";
import UploadSolution from "./UploadSolution";

import autosize from 'autosize';
import { DebounceInput } from 'react-debounce-input';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

const Question = ({
  question,
  selectedAnswer,
  results = null, // currently only used for file previews
  resultCorrectAnswer,
  status = null,
  recordAnswer,
  unrecordAnswer,
  openHint,
  flipQuestion,
  realMath = false,
  pointsMap = null,
  renderingForSelfAssessment = false,
  renderingFromAdminPreview = false,
  examModeRunning = false,
}) => {
  function answerById(answerId) {
    if (question.answers) {
      return question.answers.find(answer => {
        return answer.id === answerId;
      });
    } else {
      return null
    }
  }

  function initialSelected() {
    switch (question.kind) {
      case 'open_with_long_answer':
        return // not sure if this applies to open_with_long_answer at all
      case 'open_with_subquestions':
        // get initial value from selectedAnswer, if present? That could be just for radio button questions.
        return question.subquestions.map((s) => s.id).reduce((m, key) => Object.assign(m, {[key]: ''}), {});
      default:
        return answerById(selectedAnswer)?.value || '';
    }
  }

  const [selected, setSelected] = useState(initialSelected())
  const [hidden, setHidden] = useState(question.answers ? false : true)
  const [uploadedFileId, setUploadedFileId] = useState(null)
  const [subquestionFiles, setSubquestionFiles] = useState({})
  const [doubleCorrectAnswer, setDoubleCorrectAnswer] = useState(null)
  const [hasFullPoints, setHasFullPoints] = useState(calculatePointsBadgeFields().hasFullPoints)
  const [pointsBadgeText, setPointsBadgeText] = useState(calculatePointsBadgeFields().text)

  useEffect(() => {
    subscribe('retake-test-clicked', onRetakeTestClicked);

    const pointsBadge = calculatePointsBadgeFields();
    setHasFullPoints(pointsBadge.hasFullPoints)
    setPointsBadgeText(pointsBadge.text)
  })

  const onRetakeTestClicked = () => {
    if (question.kind === 'open_with_subquestions' || question.kind === 'open_with_long_answer_subquestions') {
      setSelected({});
    } else {
      setSelected(null);
    }

    setDoubleCorrectAnswer(null);
    setHasFullPoints(false);
  }

  const onAnswerChange = (answerValue) => {
    let answer = question.answers.find(answer => {
      return answer.value === answerValue;
    });

    setSelected(answerValue)
    if (!isDouble()) {
      recordAnswer({[question.id]: answer.id});
    } else {
      recordDoubleAnswer();
    }
  }

  const onTextAnswerChange = (value) => {
    let val = value.target.value;

    setSelected(val);

    if (val.length === 0) {
      unrecordAnswer(question.id);
    } else {
      recordAnswer({[question.id]: val});
    }
  }

  const onLongTextAnswerChange = (value) => {
    let val = value.target.value

    setSelected(val);
    autosize(value.target);

    if (val.length === 0 && uploadedFileId == null) {
      unrecordAnswer(question.id)
    } else {
      let answerObject = {answer: val, file_answer: uploadedFileId, marked_as_correct: 'pending'}
      recordAnswer({[question.id]: answerObject});
    }
  }

  const onSubquestionAnswerChange = (e, subquestion) => {
    const val = e.target.value

    setSelected({
      ...selected,
      [subquestion.id]: val
    })

    const areAllSubquestionsUnanswered = val.length === 0 && Object.values(selected).filter((v) => v.id != subquestion.id && v.length > 0).length === 0;
    if (areAllSubquestionsUnanswered) {
      unrecordAnswer(question.id)
    } else {
      recordAnswer({[question.id]: {...selected, [subquestion.id]: val}})
    }
  }

  const onLongAnswerSubquestionAnswerChange = (e, subquestion) => {
    const val = e.target.value
    const answerObject = {answer: val, file_answer: subquestionFiles[subquestion.id], marked_as_correct: 'pending'}

    setSelected({
      ...selected,
      [subquestion.id]: answerObject
    })

    autosize(e.target);

    const areAllSubquestionsUnanswered = val.length === 0 && Object.values(selected).map((v) => v.answer).filter((v) => v.id != subquestion.id && v.length > 0).length === 0;
    if (areAllSubquestionsUnanswered) {
      unrecordAnswer(question.id)
    } else {
      recordAnswer({[question.id]: {...selected, [subquestion.id]: answerObject}})
    }
  }

  const answerClicked = (answer) => {
    if (answer.value === selected) {
      setSelected(null)
      unrecordAnswer(question.id)
    }
  }

  function hintIcon() {
    return (
      <ChatBubbleOvalLeftEllipsisIcon
        className="shadow-subtle text-neutral-600 hover:text-neutral-950 cursor-pointer p-1 rounded-full h-6 w-6 flex-shrink-0"
        aria-hidden="true"
        onClick={() => openHint(question)}
      />
    )
  }

  // this may be united with isCorrectAnswer?
  function isWrongAnswer(subquestion = null) {
    if (subquestion === null) {
      if (isDouble()) {
        if (correct() == null || question.kind === 'open_with_long_answer') {
          return false
        }

        if (question.kind === 'classic') {
          return correct() != question.answers.find((a) => a.value === selected)?.id;
        }
      }

      if (status != null) {
        return status === 'wrong'
      }
      return correct() != null && selectedAnswer != correct();
    }

    if (selectedAnswer && selectedAnswer[subquestion.id]?.marked_as_correct === false) {
      return true;
    }
    if (selectedAnswer && (selectedAnswer[subquestion.id]?.marked_as_correct === true || selectedAnswer[subquestion.id]?.marked_as_correct === 'pending')) {
      return false;
    }
    return correct() && correct()[subquestion.id] != null && selectedAnswer[subquestion.id] != correct()[subquestion.id];
  }

  // this may be united with isWrongAnswer?
  function isCorrectAnswer(subquestion = null) {
    if (subquestion === null) {
      if (status != null) {
        return status === 'guessed'
      }
      return correct() != null && selectedAnswer === correct();
    }

    if (selectedAnswer && selectedAnswer[subquestion.id]?.marked_as_correct === false) {
      return false
    }
    if (selectedAnswer && selectedAnswer[subquestion.id]?.marked_as_correct === true) {
      return true
    }

    return correct() && correct()[subquestion.id] != null && selectedAnswer[subquestion.id] === correct()[subquestion.id]
  }

  function isPendingSelfAssessment(subquestion = null) {
    if (correct() == null) {
      return false // test not finished yet
    }

    if (subquestion === null && status != null) {
      return status === 'pending'
    }

    if (question.kind === 'open_with_long_answer_subquestions') {
      return (status && status[subquestion.id]?.marked_as_correct === 'pending')
    }

    return false
  }

  function icon(checked) {
    if (checked && isWrongAnswer()) {
      return <XMarkIcon />
    }

    // third condition in the OR sequence is for double questions
    // obviously can be refactored
    if (checked && (correct() == null || selectedAnswer === correct() || (question.kind === 'classic' && correct() === question.answers.find((a) => a.value === selected)?.id))) {
      return <CheckIcon />
    }
  }

  function optionColor (answer, checked) {
    if (checked && isWrongAnswer()) {
      return 'bg-neutral-500/50'
    } else if (correct() != null && correct() === answer.id && correct() != selectedAnswer) {
      return 'bg-green-600/75'
    } else if (correct() != null && correct() === answer.id && correct() === selectedAnswer) {
      return 'bg-green-600/75'
    }

    return checked ? 'bg-sky-900/75' : 'bg-white hover:bg-neutral-50'
  }

  function optionCursor() {
    return isDisabled() ? 'cursor-default' : 'cursor-pointer'
  }

  function isWhiteOption (answer, checked) {
    return checked || (answer.id === correct())
  }

  function isDisabled() {
    return correct() != null
  }

  function renderHint() {
    if (renderingForSelfAssessment || examModeRunning) {
      return;
    }

    return (
      <div className="absolute right-0">
        <Tooltip
          text="Отвори жокер към въпроса"
          object={hintIcon()}
          classes="-mt-24 w-32 text-center"
        />
      </div>
    )
  }

  function doubleIcon() {
    if (renderingForSelfAssessment || renderingFromAdminPreview || examModeRunning) {
      return;
    }

    const tooltip = isDouble() ? 'Върни се към оригиналния въпрос' : (correct() != null ? 'Отвори спомагателен въпрос' : 'Реши спомагателен въпрос - след това оригиналния');
    const classes = isDouble() ? '-mt-8 w-28' : (correct() != null ? '-mt-8 w-28' : '-mt-8 w-40');
    return (
      <>
        <div className={`absolute right-0 ${question.hint !== undefined ? 'mt-8' : ''}`}>
          <Tooltip
            text={tooltip}
            object={
              <ArrowPathIcon
                className="animate-pulse hover:animate-none bg-yellow-300 shadow-subtle text-neutral-700 hover:text-neutral-950 cursor-pointer bg-yellow-200 p-1 rounded-full h-6 w-6 flex-shrink-0"
                aria-hidden="true"
                onClick={() => flipQuestion(question)}
              />
            }
            classes={`${classes} text-center`}
          />
        </div>
      </>
    )
  }

  function renderAnswers() {
    return (
      <RadioGroup value={selected} onChange={onAnswerChange} disabled={isDisabled()}>
        <div className="space-y-2 px-3 max-w-[90%] md:max-w-lg">
          {question.answers.map((answer) => (
            <div key={answer.id}>
              <RadioGroup.Option
                value={answer.value}
                onClick={() => answerClicked(answer)}
                className={({ active, checked }) =>
                  `${active ? 'ring-1 ring-green/40 ring-offset-2 ring-offset-primary-400/50' : ''}
                  ${optionColor(answer, checked)}
                  ${optionCursor()}
                  relative flex rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-normal ${
                              isWhiteOption(answer, checked) ? 'transition-all duration-300 text-white' : 'text-neutral-900'
                            }`}
                          >
                          {realMath ? <MyRealMath text={answer.value}/> : <MyMath text={answer.value}/>}
                          </RadioGroup.Label>
                        </div>
                      </div>
                      {icon(checked)}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
              {(correct() != null || renderingFromAdminPreview) && answer.explanation && (
                <div className={`mx-1 px-2 sm:mx-3 sm:px-3 py-2 mb-2 leading-5 text-xs whitespace-pre-line rounded-b-lg overflow-x-auto overflow-y-hidden ${answer.correct ? 'bg-green-100/75 text-green-700' : 'bg-neutral-100 text-neutral-600'}`}><MyRealMath text={answer.explanation}/></div>
              )}
            </div>
          ))}
        </div>
      </RadioGroup>
    )
  }

  function answerSection() {
    switch (question.kind) {
      case 'open':
        return renderOpenQuestion();
      case 'open_with_subquestions':
        return renderQuestionWithSubquestions();
      case 'open_with_long_answer_subquestions':
        return renderQuestionWithLongAnswerSubquestions();
      case 'open_with_long_answer':
        return renderLongAnswerQuestion();
      default:
        return renderAnswers();
    }
  }

  function textAnswerIcon(subquestion = null) {
    if (isPendingSelfAssessment(subquestion)) {
      return <QuestionIcon />
    }

    if (isWrongAnswer(subquestion)) {
      return <XMarkIcon />
    }

    if (isCorrectAnswer(subquestion)) {
      return <CheckIcon fill="#16a34a" opacity="0.75"/>
    }

    if (subquestion === null && selectedAnswer != '' || subquestion !== null && selectedAnswer && selectedAnswer[subquestion.id] != '') {
      return <CheckIcon fill="#b8b8b8" stroke="#bbb" />
    }
  }

  function textAnswerColorClasses(subquestion = null) {
    if (subquestion === null && isWrongAnswer() || subquestion !== null && isWrongAnswer(subquestion)) {
      return 'text-red-900 ring-1 focus:ring-2 ring-red-300 placeholder:text-red-300 focus:ring-red-500 cursor-default'
    } else if (isCorrectAnswer(subquestion)) {
      return 'text-green-900 ring-2 ring-green-300 placeholder:text-green-300 focus:ring-green-500 cursor-default'
    } else if (subquestion === null && selectedAnswer === '' || subquestion && (selectedAnswer || selectedAnswer === '') && (selectedAnswer[subquestion.id] == null || selectedAnswer[subquestion.id] === '')) {
      return 'ring-neutral-300 ring-1 placeholder:text-primary-300 focus:ring-neutral-400'
    } else {
      return 'ring-primary-900/75 ring-2 focus:ring placeholder:text-primary-300'
    }
  }

  function renderOpenQuestion() {
    return (
      <div className="px-3 max-w-[90%] md:max-w-lg">
        <label htmlFor={question.id} className="block text-sm font-light italic leading-6 text-neutral-800">
          Отговор:
        </label>
        <div className="relative mt-2 rounded-md shadow-md">
          <DebounceInput
            debounceTimeout={200}
            name={question.id}
            id={question.id}
            value={selectedAnswer}
            onChange={onTextAnswerChange}
            onKeyDown={onInputKeyDown}
            disabled={correct() != null}
            className={'block w-full rounded-md border-0 py-3 pr-10 ring-inset focus:ring-inset leading-6 ' + textAnswerColorClasses()}
            placeholder=""
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {textAnswerIcon()}
          </div>
        </div>
        {(correct() != null || renderingFromAdminPreview) && question.explanation && (
          <div className={`mx-1 px-2 sm:mx-3 sm:px-3 py-2 mb-2 leading-5 text-xs whitespace-pre-line rounded-b-lg ${isWrongAnswer() ? 'bg-neutral-100 text-neutral-600' : 'bg-green-100/75 text-green-700'}`}><MyRealMath text={question.explanation}/></div>
        )}
        {isWrongAnswer() && (
          <p className="mt-2 text-sm text-red-600" id="answer-error">
            Верен отговор: <strong>{correct()}</strong>
          </p>
        )}
      </div>
    )
  }

  function onFileProcessed(file, subquestion = null) {
    if (subquestion == null) {
      setUploadedFileId(file.serverId);
      recordAnswer({[question.id]: {answer: selectedAnswer, file_answer: file.serverId, marked_as_correct: 'pending'}});
    } else {
      setSubquestionFiles({
        ...subquestionFiles,
        [subquestion.id]: file.serverId
      });

      const answerObject = {
        answer: selected[subquestion.id] && selected[subquestion.id]['answer'],
        file_answer: file.serverId,
        marked_as_correct: 'pending'
      }
      recordAnswer({[question.id]: {...selected, [subquestion.id]: answerObject}})
    }
  }

  function onFileCanceled(subquestion = null) {
    if (subquestion == null) {
      setUploadedFileId(null);

      if (selected == null || selected.length === 0) {
        unrecordAnswer(question.id);
      } else {
        let answerObject = {answer: selected, file_answer: null, marked_as_correct: 'pending'}
        recordAnswer({[question.id]: answerObject});
      }
    } else {
      const {[subquestion.id]: _, ...rest} = subquestionFiles;
      setSubquestionFiles(rest);
    }
  }

  function renderFileUploadOrDisplay(subquestion = null) {
    return (
      <>
        {correct() == null && !isDouble() && (
          <>
            <p className="block text-sm font-light italic leading-6 text-neutral-800 py-3"> или качете файл: </p>
            <UploadSolution subquestion={subquestion} onFileProcessed={onFileProcessed} onFileCanceled={onFileCanceled} forceHide={isDouble()}/>
          </>
        )}
        {subquestion == null && correct() != null && results && results['file_answer'] && (
          <FilePreview fileName={results['file_answer'][1]} fileUrl={results['file_answer'][2]}/>
        )}
        {subquestion != null && correct() != null && results && results[subquestion.id] && results[subquestion.id]['file_answer'] && (
          <FilePreview fileName={results[subquestion.id]['file_answer'][1]} fileUrl={results[subquestion.id]['file_answer'][2]}/>
        )}
      </>
    )
  }

  function renderSelfAssessMessage(message) {
    return (
      <>
        <div className="mt-3 rounded-md bg-cyan-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-cyan-500" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-cyan-700">{message}</p>
              <p className="mt-3 text-sm md:ml-6 md:mt-0 cursor-pointer">
                <span onClick={selfAssessClicked} className="flex whitespace-nowrap font-medium text-cyan-700 hover:text-cyan-600">
                  Оцени
                  <span className="my-auto pl-0.5" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                      <path d="M13.488 2.513a1.75 1.75 0 0 0-2.475 0L6.75 6.774a2.75 2.75 0 0 0-.596.892l-.848 2.047a.75.75 0 0 0 .98.98l2.047-.848a2.75 2.75 0 0 0 .892-.596l4.261-4.262a1.75 1.75 0 0 0 0-2.474Z" />
                      <path d="M4.75 3.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V9A.75.75 0 0 1 14 9v2.25A2.75 2.75 0 0 1 11.25 14h-6.5A2.75 2.75 0 0 1 2 11.25v-6.5A2.75 2.75 0 0 1 4.75 2H7a.75.75 0 0 1 0 1.5H4.75Z" />
                    </svg>
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  function renderLongAnswerQuestion() {
    if (renderingForSelfAssessment) {
      return;
    }

    return (
      <div className="px-3 max-w-[90%] md:max-w-lg">
        <label htmlFor={question.id} className="block text-sm font-light italic leading-6 text-neutral-800">
          Отговор:
        </label>
        <div className="relative mt-2 rounded-md shadow-md">
          <DebounceInput
            element="textarea"
            debounceTimeout={200}
            name={question.id}
            id={question.id}
            value={selectedAnswer}
            onChange={onLongTextAnswerChange}
            disabled={correct() != null}
            className={'block w-full rounded-md border-0 py-3 pr-10 ring-inset focus:ring-inset leading-6 ' + textAnswerColorClasses()}
            placeholder=""
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {textAnswerIcon()}
          </div>
        </div>
        {renderFileUploadOrDisplay()}
        {isPendingSelfAssessment() && renderSelfAssessMessage('Този въпрос трябва да оцените сами.')}
        {(isWrongAnswer() || (isDouble() && correct() != null)) && (
          <>
            <p className="block text-sm font-light italic leading-6 mt-6 mb-3 text-sm text-neutral-800"> Примерен верен отговор:</p>
            <p className="leading-6"><MyRealMath text={correct()}/></p>
          </>
        )}
      </div>
    )
  }

  // to be refactored
  function renderQuestionWithSubquestions() {
    return (
      <>
        {question.subquestions.map(subquestion => (
          <div key={subquestion.id} className="px-3 max-w-[90%] md:max-w-lg">
            <h4 className="questionTitle whitespace-pre-line text-base font-medium text-neutral-600 dark:text-white leading-6 py-7 overflow-x-auto overflow-y-hidden">
              {realMath ? <MyRealMath text={subquestion.title}/> : <MyMath text={subquestion.title}/>}
            </h4>
            <label htmlFor={subquestion.id} className="block text-sm font-light italic leading-6 text-neutral-800">
              Отговор:
            </label>
            <div className="relative mt-2 rounded-md shadow-md">
              <DebounceInput
                debounceTimeout={200}
                name={subquestion.id}
                id={subquestion.id}
                value={selectedAnswer && selectedAnswer[subquestion.id]}
                onChange={e => onSubquestionAnswerChange(e, subquestion)}
                disabled={correct() && correct()[subquestion.id] != null}
                className={'block w-full rounded-md border-0 py-3 pr-10 ring-inset focus:ring-inset leading-6 ' + textAnswerColorClasses(subquestion)}
                placeholder=""
              />

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                {textAnswerIcon(subquestion)}
              </div>
            </div>
            {(correct() != null || renderingFromAdminPreview) && subquestion.explanation && (
              <div className={`mx-1 px-2 sm:mx-3 sm:px-3 py-2 mb-2 leading-5 text-xs whitespace-pre-line rounded-b-lg ${isWrongAnswer(subquestion) ? 'bg-neutral-100 text-neutral-600' : 'bg-green-100/75 text-green-700'}`}><MyRealMath text={subquestion.explanation}/></div>
            )}
            {isWrongAnswer(subquestion) && (
              <p className="mt-2 text-sm text-red-600" id="answer-error">
                Верен отговор: <strong>{correct()[subquestion.id]}</strong>
              </p>
            )}
          </div>
        ))}
      </>
    )
  }

  function renderQuestionWithLongAnswerSubquestions() {
    return (
      <>
        {question.subquestions.map(subquestion => (
          <div key={subquestion.id} className="px-3 max-w-[90%] md:max-w-lg">
            <h4 className="questionTitle whitespace-pre-line text-base font-medium text-neutral-600 dark:text-white leading-6 py-7 overflow-x-auto overflow-y-hidden">
              {realMath ? <MyRealMath text={subquestion.title}/> : <MyMath text={subquestion.title}/>}
            </h4>

            {!renderingForSelfAssessment && (
              <>
                <label htmlFor={subquestion.id} className="block text-sm font-light italic leading-6 text-neutral-800">
                  Отговор:
                </label>
                <div className="relative mt-2 rounded-md shadow-md">
                  <DebounceInput
                    element="textarea"
                    debounceTimeout={200}
                    name={subquestion.id}
                    id={subquestion.id}
                    value={selectedAnswer && selectedAnswer[subquestion.id] && selectedAnswer[subquestion.id].answer}
                    onChange={e => onLongAnswerSubquestionAnswerChange(e, subquestion)}
                    disabled={correct() && correct()[subquestion.id] != null}
                    className={'block w-full rounded-md border-0 py-3 pr-10 ring-inset focus:ring-inset leading-6 ' + textAnswerColorClasses(subquestion)}
                    placeholder=""
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    {textAnswerIcon(subquestion)}
                  </div>
                </div>
                {renderFileUploadOrDisplay(subquestion)}
                {isPendingSelfAssessment(subquestion) && renderSelfAssessMessage('Тази подточка трябва да оцените сами.')}
                {isWrongAnswer(subquestion) && (
                  <>
                    <p className="block text-sm font-light italic leading-6 mt-6 mb-3 text-sm text-neutral-800"> Примерен верен отговор:</p>
                    <p className="leading-6"><MyRealMath text={correct()[subquestion.id]}/></p>
                  </>
                )}
              </>
            )}
          </div>
        ))}
      </>
    )
  }

  function selfAssessClicked() {
    publish('start-self-assessment-clicked');
  }

  function isDouble() {
    return question.doubles_question_id
  }

  function recordDoubleAnswer() {
    switch (question.kind) {
      case 'classic': {
        setDoubleCorrectAnswer(question.answers.find((a) => a.correct === true).id)
        break;
      }
      case 'open': {
        setDoubleCorrectAnswer(question.text_answer)
        break;
      }
      case 'open_with_long_answer': {
        setDoubleCorrectAnswer(question.long_text_answer);
        break;
      }
    }
  }

  function doubleAlert() {
    return (
      <div className="rounded-md bg-yellow-100 p-4 shadow">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-yellow-500" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-700">Спомагателен въпрос</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                Следният спомагателен въпрос е аналогичен на оригиналния въпрос от теста. Упражнете се с него и се върнете към оригиналния въпрос.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function onInputKeyDown(e) {
    if (e.key === 'Enter' && isDouble()) {
      recordDoubleAnswer();
    }
  }

  function calculatePointsBadgeFields() {
    let text = null;
    let fullPoints = null;
    const total = question.display_points;

    if (pointsMap) {
      const scored = pointsMap[question.id] && Math.round(pointsMap[question.id]['points']) || 0;
      fullPoints = scored === total;
      text = (fullPoints || scored === 0) ? total : `${scored}/${total}`;
    } else {
      fullPoints = false;
      text = total;
    }

    return {
      text: text,
      hasFullPoints: fullPoints
    }
  }

  function renderPoints() {
    return (
      <span className={`absolute ${pointsMap ? 'md:-left-14' : 'md:-left-11'} ${hasFullPoints ? 'bg-green-600/75 text-white shine-on-hover' : 'bg-neutral-50 text-neutral-500'} -left-1 -top-1.5 md:top-auto shadow inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ring-neutral-500/10 cursor-default`}>
        {pointsBadgeText} т.
      </span>
    )
  }

  function renderQuestion() {
    return (
      <div className="relative mx-auto w-full max-w-xl">
        {isDouble() && doubleAlert()}
        {question.text_above_question && question.text_above_question.length > 0 && (
          <h4 className="questionTitle whitespace-pre-line text-base font-medium text-neutral-600 dark:text-white leading-6 py-7 overflow-x-auto overflow-y-hidden">
            {realMath ? <MyRealMath text={question.text_above_question}/> : <MyMath text={question.text_above_question}/>}
          </h4>
        )}

        <h4 className="questionTitle whitespace-pre-line text-base font-medium text-neutral-600 dark:text-white leading-6 py-7 overflow-x-auto overflow-y-hidden">
          {question.display_points && renderPoints()}
          {question.index}. {realMath ? <MyRealMath text={question.title}/> : <MyMath text={question.title}/>}
        </h4>

        {question.image_url && (
          <img src={question.image_url} className="shadow-subtle max-h-80 my-8 mx-auto" />
        )}

        {question.note_below_question && question.note_below_question.length > 0 && (
          <p className="text-sm text-neutral-600 pb-7 whitespace-pre-line overflow-x-auto overflow-y-hidden">
            {realMath ? <MyRealMath text={question.note_below_question}/> : <MyMath text={question.note_below_question}/>}
          </p>
        )}

        {!hidden && question.hint !== undefined && renderHint()}
        {!hidden && (question.double || isDouble()) && doubleIcon()}
        {!hidden && answerSection()}
        {!hidden && isDouble() && question.kind !== 'classic' && correct() == null && (
          <button onClick={recordDoubleAnswer} type="button" className="inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-3 py-2 text-sm font-semibold text-yellow-600 shadow-sm hover:bg-yellow-200 mt-7">
            <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Разкрий отговор
          </button>
        )}
      </div>
    )
  }

  function correct() {
    return isDouble() ? doubleCorrectAnswer : resultCorrectAnswer;
  }

  return (
    <div className={`w-full md:px-4 lg:px-4 py-8 ${isDouble() ? 'bg-yellow-50 rounded-xl px-3' : 'px-1'} ${!isDouble() && hasFullPoints ? 'bg-green-50 rounded-xl px-3' : ''}`}>
      {renderQuestion()}
    </div>
  )
};

function CheckIcon({
  fill = '#ccc',
  stroke = '#eee',
  opacity = '0.2'
}) {
  return (
    <div className="shrink-0 text-green-600">
      <svg viewBox="0 0 24 24" fill="none" className="h-5 w-5">
        <circle cx={12} cy={12} r={12} fill={fill} opacity={opacity} />
        <path
          d="M7 13l3 3 7-7"
          stroke={stroke}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

function XMarkIcon(props) {
  return (
    <div className="shrink-0 text-red-500">
      <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="h-5 w-5" {...props}>
        <circle cx={12} cy={12} r={12} fill="#f77" opacity="0.7" />
        <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
  )
}

function QuestionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
    </svg>
  )
}

export default Question;
