import React from "react";
import { NavLink } from "react-router-dom";

import { Form, Formik } from "formik";
import { Button } from "neetoui";
import { Input } from "neetoui/formik";
import PropTypes from "prop-types";

import FacebookLoginButton from './FacebookLoginButton'
import authenticationApi from "apis/authentication";
import { LOGIN_PATH, DASHBOARD_PATH } from "components/routeConstants";
import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";

import {
  SIGNUP_FORM_INITIAL_VALUES,
  SIGNUP_FORM_VALIDATION_SCHEMA,
} from "./constants";

import LogoSpin from "components/commons/LogoSpin";

const Signup = ({ history }) => {
  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  const handleSubmit = async formData => {
    try {
      const {
        data: { auth_token, user, is_admin },
      } = await authenticationApi.signup(formData);

      let email = user.email
      authDispatch({ type: "LOGIN", payload: { auth_token, email, is_admin } });
      userDispatch({ type: "SET_USER", payload: { user } });
      history.push(DASHBOARD_PATH);
    } catch (error) {
      logger.error(error);
    }
  };

  function signupForm() {
    return (
      <div className="neeto-ui-bg-neutral-100 flex h-screen w-screen flex-row items-center justify-center p-6">
        <div className="mx-auto flex h-full w-full flex-col items-center justify-center sm:max-w-md">
          <NavLink to="/" className="mb-10 mt-24 sm:mt-1">
            <LogoSpin height="small" />
          </NavLink>
          <Formik
            initialValues={SIGNUP_FORM_INITIAL_VALUES}
            validationSchema={SIGNUP_FORM_VALIDATION_SCHEMA}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <div className="neeto-ui-rounded-md neeto-ui-bg-white neeto-ui-shadow-s w-full border py-7 px-10">
                <h2 className="neeto-ui-text-neutral-800 mb-3 text-center text-2xl font-extrabold">
                  Регистрация
                </h2>
                <div className="pt-5">
                  <FacebookLoginButton />
                  <p className="text-center font-light text-neutral-600 py-4"> или регистрация с имейл </p>
                </div>
                <Form className="w-full space-y-4">
                  <Input
                    label="Имейл"
                    name="email"
                    placeholder=""
                    type="email"
                  />
                  <div className="flex gap-x-2">
                    <Input
                      label="Име"
                      name="firstName"
                      placeholder=""
                      type="text"
                    />
                    <Input
                      label="Фамилия"
                      name="lastName"
                      placeholder=""
                      type="text"
                    />
                  </div>
                  <Input
                    label="Парола"
                    name="password"
                    placeholder="********"
                    type="password"
                  />
                  <Input
                    label="Потвърдете паролата"
                    name="passwordConfirmation"
                    placeholder="********"
                    type="password"
                  />
                  <Button
                    fullWidth
                    className="h-9 bg-primary-600 hover:bg-primary-700"
                    disabled={isSubmitting}
                    label="Регистрация"
                    loading={isSubmitting}
                    size="small"
                    type="submit"
                  />
                </Form>
                <div className="mt-4 flex flex-row items-center justify-center space-x-1 pb-2">
                  <p className="neeto-ui-text-neutral-600 font-normal">
                    Вече имате регистрация?
                  </p>
                  <Button label="Вход" size="small" style="link" to={LOGIN_PATH} className="text-primary-600 hover:text-primary-700" />
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    )
  }

  return (
    <div className="relative">
      <video loop autoPlay playsInline muted loop className="absolute z-minus top-0 left-0 w-full h-full object-cover">
        <source src="w7.mp4" type="video/mp4" />
      </video>

      {signupForm()}
    </div>
  );
};

Signup.propTypes = {
  history: PropTypes.object,
};

export default Signup;
