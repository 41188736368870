import React from "react";

import { RadioGroup } from '@headlessui/react'
import Tooltip from "components/commons/Tooltip";
import { BackwardIcon } from '@heroicons/react/24/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const enabledClasses = 'cursor-pointer text-neutral-900 hover:bg-neutral-50 shadow-subtle transition-colors duration-200 ease-in-out'
const disabledClasses = 'text-neutral-300 cursor-default shadow'

const GradeFilter = ({
  grade,
  setGrade,
  allGrades
}) => {
  return (
    <>
      <div className="flex gap-1.5">
        <h3 className="text-base font-semibold leading-6 text-neutral-900">Изберете клас</h3>

        {!grade && (
          <Tooltip
            text="Започнете оттук"
            classes="-mt-24 text-center"
            object={
              <BackwardIcon aria-hidden="true" className="w-6 h-6 text-cyan-500 animate-pulse" />
            }
          />)}
      </div>

      <div className="mt-2 max-w-l text-sm text-neutral-500">
        <p>
          Започнете търсенето чрез избор на един от следните класове.
        </p>
      </div>
      <div className="mt-5"></div>
      <div>
        <RadioGroup value={grade} onChange={setGrade} className="mt-2">
          <div className="grid w-full gap-1.5 grid-cols-3 md:grid-cols-9">
            {allGrades.map((availableGrade) => (
              <RadioGroup.Option
                key={availableGrade.id}
                value={availableGrade.id}
                disabled={!availableGrade.enabled}
                className={({ active, checked }) =>
                  classNames(
                    'focus:outline-none',
                    active ? 'ring-2 ring-primary-700 ring-offset-2' : '',
                    checked
                      ? 'bg-primary-700 text-white hover:bg-primary-700/[.95] transition-colors duration-200 ease-in-out'
                      : 'bg-white',
                    availableGrade.enabled ?  enabledClasses : disabledClasses,
                    'flex items-center justify-center rounded-sm py-2.5 px-0.5 text-sm font-medium sm:flex-1'
                  )
                }
              >
                <RadioGroup.Label as="span">{availableGrade.title}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
};

export default GradeFilter;
